<template>
  <div>
    <div
      v-for="(item, i) in colors"
      :key="i"
      class="d-block"
      :style="`height: 80px; background: ${item}`"
    >
      {{ item }}
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      colors: [],
    }
  },

  mounted() {
    const Values = require('values.js')

    const original = `#${this.$route.query.color || 'FFFFFF'}`

    const color = new Values(original)

    this.colors = []

    color.shades(24).forEach(shade => {
      this.colors.push(shade.hexString())
    })

    this.colors.reverse()

    this.colors.push(original)

    color.tints(7).forEach(shade => {
      this.colors.push(shade.hexString())
    })
  },
}
</script>

<style></style>
